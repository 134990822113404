
































































import { apiAwardIntegralGet, apiAwardIntegralSet } from '@/api/marketing'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ConsumptionReward extends Vue {
    $refs!: any
    loading = false
    formDate = {
        open_award: 0,
        award_event: '',
        award_ratio: ''
    }

    formRules = {
        award_event: [
            { required: true, message: '请选择赠送积分事件', trigger: 'blur' }
        ],
        award_ratio: [
            { required: true, message: '请输入赠送积分比例', trigger: 'blur' }
        ]
    }

    // 设置
    setConfig() {
        this.$refs.formRef.validate((valid: boolean) => {
            if (valid) {
                apiAwardIntegralSet(this.formDate)
            } else {
                return false
            }
        })
    }

    // 获取设置
    getConfig() {
        this.loading = true
        apiAwardIntegralGet()
            .then(res => {
                this.formDate = res
            })
            .finally(() => {
                this.loading = false
            })
    }

    created() {
        this.getConfig()
    }
}
